(function($, ns) {
  "use strict";

  var cfg = {
    cache: {
      container: '[data-component="syte.banner-game"]',
      gameUnavailable: ".game-unavailable",
      game: ".next-game",
      home: ".next-game-home",
      guests: ".next-game-guests",
      datetime: ".next-game-game-information-datetime",
      location: ".next-game-game-information-location"
    },
    classes: {
      loading: "loading"
    },
    data: {
      teamId: "team-id",
      teamCupId: "team-cup-id"
    },
    events: {},
    options: {},
    scripts: {
      teamCall: `${window.location.protocol}//${document.location.hostname}/wp-json/volley_api/teams/{teamId}/upcoming`,
      teamCupCall: `${window.location.protocol}//${document.location.hostname}/wp-json/volley_api/teams/{teamId}/cup/{teamCupId}/upcoming`
    }
  };

  ns.BannerGame = function(container) {
    this.container = container;
    this.settings = cfg;
    this.init();
  };

  ns.BannerGame.prototype = {
    init: function() {
      if (this.container.length) {
        this.cacheItems();
        this.bindEvents();
        this.activate();
      }
    },
    cacheItems: function() {
      var cache = this.settings.cache;

      this.win = $(window);

      this.gameUnavailable = this.container.find(cache.gameUnavailable);
      this.game = this.container.find(cache.game);
      this.home = this.container.find(cache.home);
      this.guests = this.container.find(cache.guests);
      this.datetime = this.container.find(cache.datetime);
      this.location = this.container.find(cache.location);
    },
    bindEvents: function() {},
    activate: function() {
      this.getUpcomingMatch();
    },

    getUpcomingMatch: function() {
      var settings = this.settings,
        classes = settings.classes,
        data = settings.data,
        scripts = settings.scripts;

      var self = this;

      var teamId = this.container.data(data.teamId);
      var teamCupId = this.container.data(data.teamCupId);
      var teamCall = scripts.teamCall.replace("{teamId}", teamId);
      if (teamCupId !== "") {
        teamCall = scripts.teamCupCall
          .replace("{teamId}", teamId)
          .replace("{teamCupId}", teamCupId);
      }

      this.container.addClass(classes.loading);
      $.ajax({
        type: "GET",
        url: teamCall,
        success: function(data) {
          if (data.matches && data.matches.length > 0) {
            self.setDataToBanner(self, data);
          } else {
            self.game.hide();
            self.gameUnavailable.show();
          }
        }
      }).done(function() {
        self.container.removeClass(classes.loading);
      });
    },

    setDataToBanner: function(self, data) {
      var nextGame = data.matches[0];
      self.home.html(self.stripSeriesFromTeam(nextGame.home.name));
      self.guests.html(self.stripSeriesFromTeam(nextGame.guests.name));
      var date = new Date(nextGame.date);
      self.datetime.html(
        nextGame.day +
          " " +
          date.toLocaleDateString("nl-BE") +
          " " +
          nextGame.hour
      );
      self.location.html(nextGame.location.name);
    },

    stripSeriesFromTeam: function(teamName) {
      if (teamName.indexOf("LEEFDAAL") > -1 && location.pathname == "/") {
        return "Heren A";
      }
      return teamName.substring(0, teamName.indexOf("("));
    }
  };

  return ns;
})(window.jQuery, window.Syte || {});
